

















































































































































































































































































































































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import ProductForm from '@/components/common/ProductForm.vue'
import DatePicker from 'vue2-datepicker'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

@Component({
  components: {
    ProductForm,
    DatePicker,
    VuePerfectScrollbar,
    Multiselect
  }
})
export default class CreatePicklPopup extends Vue {
  @Prop() popupId!: string
  @Prop() brandId!: number
  @Prop() userId!: number
  @Prop() OnBoarded!: number
  @Prop() LoadingBrand!: boolean
  @Prop() surveyFeatureAvailable!: boolean
  @Prop() lockPicklFeature!: boolean

  public products: any = []
  public productLimitPerPage: number = 100
  public productOffset: number = 0
  public storeLimitPerPage: number = 100
  public step: number = 1
  public showProductForm: boolean = false
  public categories = []
  public selectedStates: any = []
  public nonGeoSurveys:any = []
  public previousPickls: any = []
  public pickl: any = {
    productId: '',
    mediaId: '',
    startDateTime: '',
    countryId: 234,
    stateIds: [],
    immediateStart: false,
    description: '',
    autoExtend: false,
    extendCounter: 0,
    extendDays: 0,
    responseCounter: '',
    brandId: 0,
    type: 'NON_GEO',
    userId: 0,
    credits: '',
    surveyId: '',
    demographicType: '',
    previousPicklIds: [],
    unlockCode: ''
  }
  public selectAll: boolean = false
  public showLimitError: boolean = false
  public taskLimitPerPage: number = 100
  public taskOffset: number = 0
  public submittingForm = false
  public val: any = ''
  public date: Date = new Date()
  public time: Date = new Date()
  public settings: any = {}
  public loadingCredits: boolean = true
  public busy: boolean = false
  public states: [] = []
  public countries: [] = []
  public limit = 100
  public offset = 0
  public actionSurvey: any = null
  public media: any[] = []
  public totalCount = 0
  public locationCatalogues:any[] =[]
  public maxExtendDays: any[] = []
  public maxExtendCounter: any[] = []
  public showSurveyField: boolean = false
  public surveyUnavailableMessage: string = ''
  public mediaPage: number = 0
  public mediaSize: number = 50
  public userRole: String = ''
  public limitPerPage: number = 100
  public surveyOffset: number = 0
  public nonGeoPickls: any[] = []

  get counterDropDownList () {
    let totalCreditsleft = this.$store.state.brandCreditsRemaining
    let counterList: any = []
    if (this.userRole === 'super-admin') {
      counterList = [1, 2, 5]
    }
    let counter = 0

    let incrementValue = 25
    while (counter <= totalCreditsleft) {
      if (counter >= 100) {
        incrementValue = 50
      }
      if (counter >= 300) {
        incrementValue = 100
      }
      counter = counter + incrementValue
      if (counter <= totalCreditsleft) {
        counterList.push(counter)
      }
    }
    return counterList
  }

  step1 () {
    if (!this.pickl.immediateStart) {
      let dateTime = this.date
      dateTime.setHours(this.time.getHours(), 0, 0)
      if (new Date().getTime() > dateTime.getTime()) {
        this.$notify({ text: 'Start Date time should be later than current time', type: 'error' })
        return false
      }
      this.pickl.startDateTime = new Date(dateTime.getTime())
    }
    this.$validator.validateAll('non-geo-pickl-form').then((result) => {
      if (result) {
        this.step = 2
      }
    })
  }
  step2 () {
    if (this.pickl.task_ids.length === 0) {
      this.$notify({ text: 'Please select Tasks', type: 'error' })
      return false
    }
    if (this.pickl.task_ids.length > this.$store.state.settings.max_tasks_per_pickl) {
      this.$notify({ text: 'You can select max ' + this.$store.state.settings.max_tasks_per_pickl + ' tasks', type: 'error' })
      return false
    }
    this.step = 3
  }

  addProduct () {
    this.busy = true;
    (this.$refs.productForm as ProductForm).addProduct()
  }

  setSelected (state: any, selected: boolean) {
    let isObjectPresent = this.selectedStates.findIndex((o:any) => o.id === state.id)
    if (isObjectPresent === -1) {
      this.selectedStates.push({ id: state.id, name: state.name })
    } else {
      this.selectedStates.splice(isObjectPresent, 1)
    }
  }

  inSelectedStatesList (store: any) {
    let isObjectPresent = this.selectedStates.find((o:any) => o.id === store.id)
    if (typeof isObjectPresent !== 'undefined') {
      return true
    } else {
      return false
    }
  }

  getNonGeoPickls (clear: boolean = true): void {
    this.$store.dispatch('getBrandPickls', { id: this.brandId, status: 'APPROVED', limit: 200, offset: 0, 'type': 'NON_GEO' }).then((response: any) => {
      this.nonGeoPickls.push(...response.response.body.data)
      console.log(this.nonGeoPickls)
    }, response => {
    })
  }

  removeRetailer (index: number) {
    this.selectedStates.splice(index, 1)
  }

  getNonGeoSurveys () {
    this.$store
      .dispatch('getSurveysList', {
        limit: this.limitPerPage,
        offset: this.surveyOffset,
        brand_id: this.brandId,
        type: 'NON_GEO'
      })
      .then(
        response => {
          this.nonGeoSurveys.push(...response.response.body.surveys)
          console.log(this.nonGeoSurveys)
        },
        response => {}
      )
  }

  onSelectAll (event: any) {
    if (this.selectAll) {
      this.states.forEach((state: any) => {
        let isObjectPresent = this.selectedStates.find((o:any) => o.id === state.id)
        if (typeof isObjectPresent !== 'undefined') {
          return false
        }
        this.selectedStates.push({ id: state.id, name: state.name })
      })
    } else {
      this.states.forEach((state: any) => {
        let selectedStateIndex = this.selectedStates.findIndex((o:any) => o.id === state.id)
        if (selectedStateIndex !== -1) {
          this.removeRetailer(selectedStateIndex)
        }
      })
    }
  }

  ValidateAndCreateNonGeoPickl () {
    this.pickl.stateIds = []
    this.selectedStates.forEach((x: any) => {
      this.pickl.stateIds.push(x.id)
    })

    if (this.pickl.stateIds.length === 0) {
      this.$notify({ text: 'Please select Stores', type: 'error' })
      return false
    }
    if (this.pickl.responseCounter === '') {
      this.$notify({ text: 'Please Select Counter For Response', type: 'error' })
      return false
    }
    if (this.userRole === 'super-admin' && this.pickl.credits === '') {
      this.$notify({ text: 'Please Enter Pickl Credits', type: 'error' })
      return false
    }
    this.previousPickls.forEach((pickl: any) => {
      this.pickl.previousPicklIds.push(pickl.id)
    })
    this.createPickl()
  }
  createPickl () {
    this.submittingForm = true
    this.$store.commit('showLoading')
    this.pickl.brandId = this.brandId
    this.pickl.userId = this.userId
    this.$store.dispatch('saveNonGeoPickl', this.pickl).then(() => {
      this.$emit('UpdatePicklList')
      this.pickl.responseCounter = ''
      this.$notify({ type: 'success', text: 'Go-Anywhere PICKL has been created successfully. You want to blast more pickls?' })
      this.submittingForm = false
      this.selectAll = false
      this.selectedStates = []
      this.onSelectAll(false)
      this.date = new Date()
      this.time = this.date
      this.time.setHours(this.time.getHours() + 1)
      this.$store.commit('showMessage', { message: 'GOOOO PICKL!' })
    }, (response) => {
      for (let key in response.body) {
        if (response.status === 400) {
        } else {
          this.$notify({ type: 'error', text: response.body[key][0] })
        }
        let field = this.$validator.fields.find({ name: key, scope: 'non-geo-pickl-form' })
        if (field) {
          this.$validator.errors.add({
            id: field.id,
            field: key,
            msg: response.body[key][0],
            scope: 'non-geo-pickl-form'
          })
        }
      }
      this.submittingForm = false
      this.$store.commit('hideMessage')
    })
  }

  refreshProductList () {
    this.busy = false
    this.showProductForm = false
    this.products = []
    this.getProducts()
    this.$emit('refreshProductList')
  }

  getProducts () {
    this.$store.dispatch('getBrandProducts', { brandId: this.brandId, limit: this.productLimitPerPage, offset: this.productOffset, sort: 'name-ASC' }).then((response: any) => {
      this.products.push(...response.response.body.data)
      if (this.products.length < response.response.body.count) {
        this.productOffset += this.productLimitPerPage
        this.getProducts()
      }
    })
  }

  getCountries () {
    this.$http.get('countries?service_enabled=true').then((response: any) => {
      this.countries = response.body.data
    })
  }

  getStates () {
    this.states = []
    this.$store.commit('showLoading', 'Loading States')
    this.$http.get('states?country_id=' + this.pickl.countryId).then((response: any) => {
      this.states = response.body.data
      this.$store.commit('hideMessage')
    })
  }

  getMedia (clear = true) {
    this.busy = true
    this.$store
      .dispatch('getMediaList', {
        limit: 100,
        offset: 0,
        brand_id: this.brandId
      })
      .then(
        response => {
          this.media.push(...response.response.body.media)
          this.busy = false
        },
        response => {
          this.busy = false
        }
      )
  }

  checkSurveyAvailibility () {
    if (this.$store.state.settings.enable_survey_feature === '1') {
      if (this.surveyFeatureAvailable === true) {
        this.showSurveyField = true
        this.getMedia()
      } else {
        this.surveyUnavailableMessage = 'Upgrade to Essential or Pro plan to use this feature'
      }
    } else {
      this.surveyUnavailableMessage = 'Coming soon! Contact PICKL support for more info.'
    }
  }

  created () {
    this.loadingCredits = true
    this.getNonGeoPickls()
    this.time.setHours(this.time.getHours() + 1)
    this.$store.dispatch('getBrandCurrentSubscription', this.brandId).then(() => { this.loadingCredits = false })
    this.getProducts()
    this.$store.dispatch('getAppSettingsKeyValuePair').then(() => {
      for (let i = 1; i <= this.$store.state.settings.max_extend_counter; i++) {
        this.maxExtendCounter.push(i)
      }
      this.maxExtendDays = this.$store.state.settings.max_extend_days

      this.checkSurveyAvailibility()
      this.pickl.credits = this.$store.state.settings.non_geo_pickl_credits
    })
    this.getCountries()
    this.getStates()
    this.getNonGeoSurveys()
    let userData = this.$store.state.userData
    this.userRole = userData.role
  }

  @Watch('surveyFeatureAvailable')
  onSurveyAvailibilityChange () {
    this.checkSurveyAvailibility()
  }
}
